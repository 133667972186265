<template>
  <div class="StepsCom-container">
    <a-page-header
      style="background: #fff; margin-bottom: 10px"
      title="返回"
      @back="$router.push({ path: '/advertisement/directpassenger' })"
    />
    <div class="step_box">
      <div class="step_container">
        <a-steps class="step_comp" size="small" :current="current">
          <a-step v-for="(item, index) in steps" :key="item.title" @click.native="handleSteps(index)">
            <div slot="icon">
              <span :class="current >= index ? 'stepSpan-active' : 'stepSpan'">{{ item.title }}</span>
            </div>
          </a-step>
        </a-steps>
        <div class="steps-content">
          <basicCom ref="basicComRef" v-show="current == 0"></basicCom>
          <advertising ref="advertisingRef" :planId="planId" v-show="current == 1"></advertising>
          <directional ref="directionalRef" :planId="planId" v-show="current == 2"></directional>
          <sourceMaterial ref="sourceMaterialRef" v-show="current == 3" :planId="planId"></sourceMaterial>
        </div>
        <div class="steps-action">
          <a-button
            style="margin-right: 20px"
            @click="$router.push({ path: '/advertisement/directpassenger' })"
          >取消</a-button
          >
          <a-button
            style="margin-right: 20px"
            @click="prev()"
            :disabled="current === 0 || nextLoading"
          >上一步</a-button
          >
          <a-button
            style="margin-right: 20px; background: #409eff; border-color: #409eff"
            v-if="current < steps.length - 1"
            type="primary"
            @click="next"
            :disabled="nextLoading"
          >{{
            this.routeId ||
              (this.current == 0 && isEditbasicComFlag) ||
              (this.current == 1 && isEditadvertisingFlag) ||
              (this.current == 2 && isEditdirectionalFlag)
              ? '更新'
              : '下一步'
          }}</a-button
          >
          <a-button
            style="background: #409eff; border-color: #409eff"
            v-if="current === steps.length - 1"
            type="primary"
            @click="next"
          >提交</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import basicCom from '../basicCom'
import advertising from '../advertising'
import directional from '../directional'
import sourceMaterial from '../sourceMaterial'
import {
  addAdPlan,
  updateAdPlan,
  directionalSetting,
  updatePutOnPlace,
  putOnPlace,
  updateCreative,
  updateDirectionalSetting,
  uploadCreative,
  getAdPlanById,
  getAdPlanPutOnPlaceById,
  getAdPlanDirectionalSettingById
} from '@/api/advertisement'
import mixDate from '@/mixins/initDate'
export default {
  name: 'StepsCom',
  mixins: [mixDate],
  components: { basicCom, advertising, directional, sourceMaterial },
  data () {
    return {
      current: 0,
      steps: [
        {
          title: '1.基本信息'
        },
        {
          title: '2.投放广告位'
        },
        {
          title: '3.定向设置'
        },
        {
          title: '4.素材管理'
        }
      ],
      planId: undefined,
      routeId: undefined,
      isEditbasicComFlag: false,
      isEditadvertisingFlag: false,
      isEditdirectionalFlag: false,
      areaOptions: [],
      nextLoading: false
    }
  },
  created () {
    const { id } = this.$route.query
    this.routeId = id
    if (id) {
      // 编辑
      this.getAdPlanByIdFn({ id })
      this.getAdPlanPutOnPlaceByIdFn({ id })
    }
  },
  methods: {
    async next () {
      this.nextLoading = true
      // validateFn
      // 基本信息
      if (this.current === 0) {
        this.$refs.basicComRef &&
          this.$refs.basicComRef.$refs.basicComForm.validate(async (validate) => {
            if (!validate) return false
            const queryData = this.$refs.basicComRef.basicComForm
            const { startDate } = this.initDate(0, 0)
            const { putTimeType, startOrEndTime } = queryData
            const tempData = {
              ...queryData,
              startDate: putTimeType === 0 ? startDate : startOrEndTime[0],
              endDate: putTimeType === 0 ? '' : startOrEndTime[1]
            }
            if (this.routeId || this.isEditbasicComFlag) {
              await this.updateAdPlanFn({ id: this.routeId, ...tempData })
            } else {
              await this.addAdPlanFn(tempData)
            }
          })
      } else if (this.current === 1) {
        const {
          name,
          settlePrice,
          impressFrequencyType,
          impressFreqDay,
          impressInterval,
          clickFrequencyType,
          clickFreqDay
        } = this.$refs.basicComRef && this.$refs.basicComRef.basicComForm
        const { placeIdList, oldPlaceIdList } = this.$refs.advertisingRef
        if (placeIdList.length === 0) return this.$message.error('请选择需要投放的广告位')
        if (this.routeId || this.isEditadvertisingFlag) {
          await this.updatePutOnPlaceFn({
            id: this.routeId || this.planId,
            name,
            settlePrice,
            impressFrequencyType,
            impressFreqDay,
            impressInterval,
            clickFrequencyType,
            clickFreqDay,
            placeIdList,
            oldPlaceIdList: oldPlaceIdList
          })
        } else {
          await this.putOnPlaceFn({
            id: this.routeId || this.planId,
            name,
            settlePrice,
            impressFrequencyType,
            impressFreqDay,
            impressInterval,
            clickFrequencyType,
            clickFreqDay,
            placeIdList
          })
        }
      } else if (this.current === 2) {
        // 定向信息
        const { releaseTime, regionTag, brandTag } = this.$refs.directionalRef
        const {
          timeInterval,
          region,
          platform,
          equipment,
          network = [],
          operator = [],
          moblieBrand,
          unlimitednetwork,
          unlimitedoperator,
          // app定向
          appAction,
          // 定向逻辑
          appInclude,
          // app定向列表
          appActionList
        } = this.$refs.directionalRef.directionalForm
        //   provinceTag: [],
        // regionTag: [],
        // brandTag: [],
        // countryLabel: undefined,
        // countryValue: undefined,
        // provinceLabel: undefined,
        // provinceValue: undefined,
        const adPlanRuleLinkList = []
        // 播放时间段
        if (timeInterval !== 0) {
          if (releaseTime.length !== 0) {
            // 合并hours时间段

            const newArray = [
              {
                dayOfWeek: '1',
                hours: []
              },
              {
                dayOfWeek: '2',
                hours: []
              },
              {
                dayOfWeek: '3',
                hours: []
              },
              {
                dayOfWeek: '4',
                hours: []
              },
              {
                dayOfWeek: '5',
                hours: []
              },
              {
                dayOfWeek: '6',
                hours: []
              },
              {
                dayOfWeek: '7',
                hours: []
              }
            ]
            releaseTime.forEach((item) => {
              newArray.forEach((child) => {
                if (+item.dayOfWeek === +child.dayOfWeek) {
                  child.hours.push(item.hoursValue)
                }
              })
            })

            // const newArray = releaseTime.reduce((total, cur, index) => {
            //   const hasValue = total.findIndex((current) => {
            //     return current.dayOfWeek === cur.dayOfWeek
            //   })
            //   if (hasValue === -1) {
            //     // 有相同的
            //     cur.hours = [cur.hoursValue]
            //     total.push(cur)
            //   }
            //   hasValue !== -1 && (total[hasValue].hours = [total[hasValue].hoursValue, cur.hoursValue])
            //   return total
            // }, [])
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 1,
              timeRuleContentVoList: newArray
            })
          }
        } else {
          // 判断是否是编辑
          if (this.routeId) {
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 1,
              timeRuleContentVoList: []
            })
          }
        }
        // 地域
        if (region !== 0) {
          if (regionTag.length !== 0) {
            const newArray = []
            regionTag.forEach((ritem) => {
              newArray.push(ritem.value)
            })
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 2,
              ruleContentList: newArray
            })
          }
        } else {
          // 判断是否是编辑
          if (this.routeId || this.isEditdirectionalFlag) {
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 2,
              ruleContentList: []
            })
          }
        }
        // 平台
        if (platform !== -1) {
          adPlanRuleLinkList.push({
            planId: this.routeId || this.planId,
            id: this.routeId || this.planId,
            ruleId: 3,
            ruleContentList: platform === -1 ? [] : [platform]
          })
        } else {
          // 判断是否是编辑
          if (this.routeId) {
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 3,
              ruleContentList: platform === -1 ? [] : [platform]
            })
          }
        }
        // 设备
        if (equipment !== -1) {
          adPlanRuleLinkList.push({
            planId: this.routeId || this.planId,
            id: this.routeId || this.planId,
            ruleId: 4,
            ruleContentList: equipment === -1 ? [] : [equipment]
          })
        } else {
          // 判断是否是编辑
          if (this.routeId) {
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 4,
              ruleContentList: equipment === -1 ? [] : [equipment]
            })
          }
        }
        // 网络
        if (unlimitednetwork !== 0) {
          adPlanRuleLinkList.push({
            planId: this.routeId || this.planId,
            id: this.routeId || this.planId,
            ruleId: 5,
            ruleContentList: network
          })
        } else {
          // 判断是否是编辑
          if (this.routeId || this.isEditdirectionalFlag) {
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 5,
              ruleContentList: network
            })
          }
        }
        // 运营商
        if (unlimitedoperator !== -1) {
          adPlanRuleLinkList.push({
            planId: this.routeId || this.planId,
            id: this.routeId || this.planId,
            ruleId: 6,
            ruleContentList: operator
          })
        } else {
          // 判断是否是编辑
          if (this.routeId) {
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 6,
              ruleContentList: operator
            })
          }
        }
        // 手机品牌
        if (moblieBrand !== 0) {
          adPlanRuleLinkList.push({
            planId: this.routeId || this.planId,
            id: this.routeId || this.planId,
            ruleId: 7,
            ruleContentList: brandTag
          })
        } else {
          // 判断是否是编辑
          if (this.routeId) {
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleId: 7,
              ruleContentList: moblieBrand !== 0 ? brandTag : []
            })
          }
        }
        // app定向
        if (appAction === 1) {
          adPlanRuleLinkList.push({
            planId: this.routeId || this.planId,
            id: this.routeId || this.planId,
            ruleType: appInclude,
            ruleId: 8,
            ruleContentList: appActionList
          })
        } else {
          // 判断是否是编辑
          if (this.routeId || this.isEditdirectionalFlag) {
            adPlanRuleLinkList.push({
              planId: this.routeId || this.planId,
              id: this.routeId || this.planId,
              ruleType: appInclude,
              ruleId: 8,
              ruleContentList: appAction === 1 ? appActionList : []
            })
          }
        }

        if (this.routeId || this.isEditdirectionalFlag) {
          if (adPlanRuleLinkList.length === 0) {
            // 所有规则不限不调用接口
            this.current++
          } else {
            await this.updateDirectionalSettingFn({ adPlanRuleLinkList })
          }
        } else {
          if (adPlanRuleLinkList.length === 0) {
            // 所有规则不限不调用接口
            this.current++
          } else {
            await this.directionalSettingFn({ adPlanRuleLinkList })
          }
        }
        this.$refs.directionalRef.provinceTag = []
        this.$refs.directionalRef.regionTag = []
        this.$refs.directionalRef.areaOptions = []
        await this.$refs.directionalRef.getAreaListFn()
        this.$refs.directionalRef.cityOptions = []
        this.$refs.directionalRef.districtOptions = []
        this.$refs.directionalRef.countryLabel = undefined
        this.$refs.directionalRef.countryValue = undefined
        this.$refs.directionalRef.provinceLabel = undefined
        this.$refs.directionalRef.provinceValue = undefined
      } else {
        // 素材管理
        const { sourceFormData } = this.$refs.sourceMaterialRef
        const listRef = this.$refs.sourceMaterialRef.$refs
        const newRefList = []
        const tempadPlanCreativeList = [] // 旧的数据
        const tempnewAdPlanCreativeList = [] // 新添加的数据
        for (const key in listRef) {
          listRef[key].map((listRefitem, index) => {
            listRefitem.validate((validate) => {
              newRefList.push(validate)
              return validate
            })
            return listRefitem
          })
        }
        // 每个表单都校验通过 才走接口
        if (newRefList.every((newitem) => newitem)) {
          sourceFormData.map((item) => {
            item.sourceForm.adPlanId = this.routeId || this.planId
            const obj = { ...item.sourceForm }
            if (item.sourceForm.id) {
              tempadPlanCreativeList.push(obj)
            } else {
              tempnewAdPlanCreativeList.push(obj)
            }
            return item
          })
          if (this.routeId) {
            await this.updateCreativeFn({
              adPlanCreativeList: tempadPlanCreativeList,
              newAdPlanCreativeList: tempnewAdPlanCreativeList
            })
          } else {
            await this.uploadCreativeFn({
              adPlanCreativeList: tempnewAdPlanCreativeList
            })
          }
        }
      }
      this.nextLoading = false
    },
    async prev (current) {
      this.nextLoading = true
      current !== undefined ? (this.current = current) : this.current--
      if (this.routeId || this.planId) {
        const id = this.routeId || this.planId
        // 编辑
        this.current === 0 && (await this.getAdPlanByIdFn({ id }))
        this.current === 1 && (await this.getAdPlanPutOnPlaceByIdFn({ id }))
        this.current === 2 && (await this.getAdPlanDirectionalSettingByIdFn({ id }))
      }
      this.nextLoading = false
    },
    // 新增基本信息
    async addAdPlanFn (data) {
      const res = await addAdPlan(data)
      if (res.code === 200 && res.data) {
        this.current++
        this.planId = res.data.id
        this.isEditbasicComFlag = true
      }
    },
    // 新增定向信息
    async directionalSettingFn (data) {
      const res = await directionalSetting(data)
      if (res.code === 200) {
        this.current++
        this.isEditdirectionalFlag = true
      }
    },
    // 新增广告主
    async putOnPlaceFn (data) {
      const res = await putOnPlace(data)
      if (res.code === 200) {
        this.current++
        this.isEditadvertisingFlag = true
      }
    },
    // 素材上传
    async uploadCreativeFn (data) {
      const res = await uploadCreative(data)
      if (res.code === 200) {
        this.$router.push({
          path: '/advertisement/directpassenger'
        })
      }
    },
    async updateCreativeFn (data) {
      const res = await updateCreative(data)
      if (res.code === 200) {
        this.$router.push({
          path: '/advertisement/directpassenger'
        })
      }
    },
    // 根据广告计划id查询基本信息详情
    async getAdPlanByIdFn (data) {
      const res = await getAdPlanById(data)
      if (res.code === 200 && res.data) {
        const { startDate, endDate, adType } = res.data
        this.$refs.basicComRef.basicComForm = { ...res.data, startOrEndTime: [startDate, endDate], adType: adType + '' }
      }
    },
    // 根据广告计划id查询广告位设置详情
    async getAdPlanPutOnPlaceByIdFn (data) {
      const res = await getAdPlanPutOnPlaceById(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data
        const newPlaceIdList = []
        tempData.length !== 0 &&
          tempData.forEach((item) => {
            item.checkAllList = []
            item.placeList.length !== 0 &&
              item.placeList.forEach((ele) => {
                ele.checked = ele.isChoosed
                ele.appId = item.appId
                if (ele.isChoosed) {
                  newPlaceIdList.push(ele.placeId)
                  item.checkAllList.push(ele)
                }
              })
          })
        this.$refs.advertisingRef.data = tempData
        this.$refs.advertisingRef.placeIdList = newPlaceIdList
        this.$refs.advertisingRef.oldPlaceIdList = newPlaceIdList
      }
    },
    // 根据广告计划id查询定向设置详情
    async getAdPlanDirectionalSettingByIdFn (data) {
      const res = await getAdPlanDirectionalSettingById(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data
        const newdirectionalForm = {
          // 投放时段
          timeInterval: 0,
          // 地域
          region: 0,
          // 平台
          platform: -1,
          // 设备
          equipment: -1,
          // 网络
          network: [],
          unlimitednetwork: 0,
          // 运营商
          operator: [],
          unlimitedoperator: -1,
          // 手机品牌
          moblieBrand: 0,
          // app定向
          appAction: 0,
          // 定向逻辑
          appInclude: 0,
          // app定向列表
          appActionList: []
        }
        const releaseTime = []
        const brandTag = []
        tempData.length !== 0 &&
          tempData.forEach((item) => {
            // 播放时间段
            if (item.ruleId === 1) {
              newdirectionalForm.timeInterval = item.ruleContentList.length === 0 ? 0 : 1
              if (item.ruleContentList.length !== 0) {
                const newruleContentList = JSON.parse(item.ruleContentList[0])
                newruleContentList.forEach((ele) => {
                  const newData = ele.hours.map((hele) => {
                    const obj = {}
                    obj.hoursValue = hele
                    obj.dayOfWeek = ele.dayOfWeek
                    obj.key = +ele.dayOfWeek + +hele
                    return obj
                  })
                  releaseTime.push(...newData)
                })
              }
            } else if (item.ruleId === 2) {
              // 地域
              this.$refs.directionalRef.regionTag = []
              newdirectionalForm.region = item.ruleContentList.length === 0 ? 0 : 1
              const newruleContentList = []
              item.ruleContentList.forEach((ruleContentListItem) => {
                newruleContentList.push(ruleContentListItem.split('-'))
              })
              newruleContentList.forEach((newItem) => {
                this.$refs.directionalRef.echoCityOptions(newItem)
              })
            } else if (item.ruleId === 3) {
              // 平台
              newdirectionalForm.platform = item.ruleContentList.length === 0 ? -1 : +item.ruleContentList[0]
            } else if (item.ruleId === 4) {
              // 设备类型
              newdirectionalForm.equipment = item.ruleContentList.length === 0 ? -1 : +item.ruleContentList[0]
            } else if (item.ruleId === 5) {
              // network
              // unlimitednetwork
              if (item.ruleContentList.join().split('').length === 0) {
                newdirectionalForm.unlimitednetwork = 0
              } else {
                newdirectionalForm.network = item.ruleContentList.map(Number)
                newdirectionalForm.unlimitednetwork = undefined
              }
            } else if (item.ruleId === 6) {
              // 运营商
              if (item.ruleContentList.length === 0) {
                newdirectionalForm.unlimitedoperator = -1
              } else {
                newdirectionalForm.operator = item.ruleContentList.map(Number)
                newdirectionalForm.unlimitedoperator = undefined
              }
            } else if (item.ruleId === 7) {
              // 手机品牌
              newdirectionalForm.moblieBrand = item.ruleContentList.length === 0 ? 0 : 1
              brandTag.push(...item.ruleContentList)
              this.$refs.directionalRef.brandOptions = this.$refs.directionalRef.brandOptions.map((bitem) => {
                if (item.ruleContentList.includes(bitem.name)) {
                  bitem.checked = true
                }
                return bitem
              })
            } else if (item.ruleId === 8) {
              // app定向
              newdirectionalForm.appAction = item.ruleContentList.length === 0 ? 0 : 1
              newdirectionalForm.appInclude = item.ruleType ? 1 : 0
              newdirectionalForm.appActionList = item.ruleContentList
            }
          })
        this.$refs.directionalRef.directionalForm = { ...newdirectionalForm }
        this.$refs.directionalRef.releaseTime = releaseTime
        this.$refs.directionalRef.brandTag = brandTag
      }
    },

    // 编辑基本信息
    async updateAdPlanFn (data) {
      const res = await updateAdPlan(data)
      if (res.code === 200) {
        this.current++
      }
    },
    // 编辑投放广告位
    async updatePutOnPlaceFn (data) {
      const res = await updatePutOnPlace(data)
      if (res.code === 200) {
        this.current++
        this.getAdPlanDirectionalSettingByIdFn({ id: this.routeId })
      }
    },
    // 编辑投放广告位
    async updateDirectionalSettingFn (data) {
      const res = await updateDirectionalSetting(data)
      if (res.code === 200) {
        this.current++
      }
    },
    handleSteps (currentIndex) {
      if (currentIndex === this.current) return
      if (currentIndex > this.current) {
        if (this.routeId) {
          // 编辑
          this.current = currentIndex
          const id = this.routeId || this.planId
          this.current === 0 && this.getAdPlanByIdFn({ id })
          this.current === 1 && this.getAdPlanPutOnPlaceByIdFn({ id })
          this.current === 2 && this.getAdPlanDirectionalSettingByIdFn({ id })
        } else {
          this.next()
        }
      } else {
        this.prev(currentIndex)
      }
    }
  }
}
</script>
<style lang="less">
.StepsCom-container {
  margin-top: -55px;
  box-sizing: border-box;
  position: relative;
  height: 90vh;
  .step_box {
    height: 91%;
    position: relative;
    margin: 0 10px;
    background-color: #fff;
    border-radius: 5px;
    .step_container {
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 99%;
      margin: 0 auto;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .step_comp {
    width: 60%;
    margin: auto;
    padding: 20px 0;
    .stepSpan {
      background: #f2f2f2;
      display: inline-block;
      color: #ababab;
      padding: 15px;
      height: 20px;
      line-height: 1px;
      border-radius: 50px;
      font-size: 14px;
    }
    .stepSpan-active {
      background: #3467fe;
      display: inline-block;
      color: #fff;
      padding: 15px;
      height: 15px;
      line-height: 1px;
      border-radius: 50px;
      font-size: 13px;
    }
  }

  .steps-content {
    border-radius: 5px;
    text-align: center;
    overflow-y: auto;
    height: 80%;
    padding: 10px;
    // padding-top: 30px;
    // border-left: 10px solid #fff;
    // border-top: 10px solid #fff;
    // border-bottom: 10px solid #fff;
    // border: 1px solid #ccc;
  }

  .steps-action {
    text-align: center;
    background: #fff;
    min-height: 100px;
    flex-grow: 1;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
