<template>
  <div>
    <a-form-model ref="basicComForm" :model="basicComForm" v-bind="layout">
      <div class="flexStyle text-left">
        <div class="flex-1">
          <a-form-model-item
            label="广告类型"
            prop="adType"
            :rules="{
              required: true,
              message: '此项为必填项',
              trigger: 'change'
            }"
          >
            <a-select allowClear v-model="basicComForm.adType" placeholder="请选择广告类型">
              <a-select-option v-for="pitem in advertiserTypeOptions" :key="pitem.value" :value="pitem.value">
                {{ pitem.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="计划名称"
            name="name"
            prop="name"
            :rules="{
              required: true,
              message: '此项为必填项',
              trigger: 'blur'
            }"
          >
            <a-input v-model.trim="basicComForm.name" placeholder="请输入计划名称" />
          </a-form-model-item>
          <a-form-model-item
            label="广告主"
            prop="userId"
            :rules="{
              required: true,
              message: '此项为必填项',
              trigger: 'change'
            }"
          >
            <a-select allowClear v-model="basicComForm.userId" placeholder="请选择广告主">
              <a-select-option v-for="item in advertiserOptions" :key="item.id" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="交互类型" class="text-left">
            <a-radio-group v-model="basicComForm.actionType">
              <a-radio :value="1">页面打开</a-radio>
              <a-radio :value="2">下载</a-radio>
              <a-radio :value="3">deeplink</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="跳转地址"
            prop="landingPage"
            class="text-left"
            :rules="{
              required: true,
              message: '此项为必填项',
              trigger: 'blur'
            }"
          >
            <a-input v-model.trim="basicComForm.landingPage" allowClear placeholder="请输入跳转地址" />
          </a-form-model-item>
          <a-form-model-item
            label="deeplink地址"
            prop="deeplink"
            :rules="{
              required: basicComForm.actionType == 3,
              message: '此项为必填项',
              trigger: 'blur'
            }"
            v-if="basicComForm.actionType == 3"
          >
            <a-input v-model.trim="basicComForm.deeplink" allowClear placeholder="请输入deeplink地址" />
          </a-form-model-item>
          <a-form-model-item prop="impressTrack">
            <div style="display: inline-block" slot="label">
              展示监测URL<a-popover slot="suffix">
                <template slot="content">
                  <div style="display: flex; justify-content: space-between">
                    <div style="color: #61627e">
                      <p style="color: #919399; font-weight: 700">已支持的占位符</p>
                      <p>__IMEI__</p>
                      <p>__OAID__</p>
                      <p>__IDFA__</p>
                      <p>__IDFV__</p>
                      <p>__OS__</p>
                      <p>__IP__</p>
                      <p>__UA__</p>
                      <p>__PACKAGE_NAME__</p>
                      <p>__MAC__</p>
                    </div>
                    <div style="padding-left: 10px; color: #61627e">
                      <p style="color: #919399; font-weight: 700">说明</p>
                      <p>安卓设备的IMEI</p>
                      <p>安卓设备的OAID</p>
                      <p>iOS设备的IDFA</p>
                      <p>设备的IDFV</p>
                      <p>操作系统信息</p>
                      <p>设备的IP地址</p>
                      <p>用户的UA标识</p>
                      <p>应用的包名</p>
                      <p>设备的MAC地址</p>
                    </div>
                  </div>
                </template>
                <a-icon
                  type="info-circle"
                  style="color: rgba(0, 0, 0, 0.45); margin-left: 5px; font-size: 12px; cursor: pointer"
                />
              </a-popover>
            </div>
            <a-input v-model.trim="basicComForm.impressTrack" allowClear placeholder="请输入展示监测URL" />
          </a-form-model-item>
          <a-form-model-item prop="clickTrack">
            <div style="display: inline-block" slot="label">
              点击监测URL<a-popover slot="suffix">
                <template slot="content">
                  <div style="display: flex; justify-content: space-between">
                    <div style="color: #61627e">
                      <p style="color: #919399; font-weight: 700">已支持的占位符</p>
                      <p>__IMEI__</p>
                      <p>__OAID__</p>
                      <p>__IDFA__</p>
                      <p>__IDFV__</p>
                      <p>__OS__</p>
                      <p>__IP__</p>
                      <p>__UA__</p>
                      <p>__PACKAGE_NAME__</p>
                      <p>__MAC__</p>
                    </div>
                    <div style="padding-left: 10px; color: #61627e">
                      <p style="color: #919399; font-weight: 700">说明</p>
                      <p>安卓设备的IMEI</p>
                      <p>安卓设备的OAID</p>
                      <p>iOS设备的IDFA</p>
                      <p>设备的IDFV</p>
                      <p>操作系统信息</p>
                      <p>设备的IP地址</p>
                      <p>用户的UA标识</p>
                      <p>应用的包名</p>
                      <p>设备的MAC地址</p>
                    </div>
                  </div>
                </template>
                <a-icon
                  type="info-circle"
                  style="color: rgba(0, 0, 0, 0.45); margin-left: 5px; font-size: 12px; cursor: pointer"
                />
              </a-popover>
            </div>
            <a-input v-model.trim="basicComForm.clickTrack" allowClear placeholder="请输入点击监测URL"> </a-input>
          </a-form-model-item>
        </div>
        <div class="flex-1">
          <a-form-model-item
            label="投放时间"
            prop="startOrEndTime"
            ref="putTimeType"
            class="text-left"
            :rules="{
              required: basicComForm.putTimeType == '1',
              message: '此项为必填项',
              trigger: 'change'
            }"
          >
            <a-radio-group v-model="basicComForm.putTimeType">
              <a-radio-button :value="0">从今天起长时间投放</a-radio-button>
              <a-radio-button :value="1">设置开始和结束时间</a-radio-button>
            </a-radio-group>
            <a-range-picker
              v-if="basicComForm.putTimeType == '1'"
              format="YYYY-MM-DD"
              v-model="basicComForm.startOrEndTime"
              :placeholder="['Start', 'End']"
              @change="handleChangePutTime"
              @blur="
                () => {
                  $refs.putTimeType.onFieldChange()
                }
              "
              :disabledDate="disabledDate"
            >
            </a-range-picker>
          </a-form-model-item>
          <a-form-model-item
            label="总预算"
            prop="totalBudget"
            :rules="{
              required: true,
              message: '此项为必填项',
              trigger: 'blur'
            }"
          >
            <a-input
              style="width: 200px"
              v-model.trim="basicComForm.totalBudget"
              placeholder="请输入总预算"
            ><span slot="addonAfter">￥</span></a-input
            >
          </a-form-model-item>
          <a-form-model-item
            label="日预算"
            prop="dayBudget"
            :rules="{
              required: true,
              message: '此项为必填项',
              trigger: 'blur'
            }"
          >
            <a-input
              style="width: 200px"
              v-model.trim="basicComForm.dayBudget"
              placeholder="请输入日结算"
            ><span slot="addonAfter">￥</span></a-input
            >
          </a-form-model-item>
          <a-form-model-item
            label="eCPM结算价格"
            prop="settlePrice"
            :rules="{
              required: true,
              message: '此项为必填项',
              trigger: 'blur'
            }"
          >
            <a-input
              style="width: 200px"
              v-model.trim="basicComForm.settlePrice"
              placeholder="请输入eCPM结算价格"
            ><span slot="addonAfter">￥</span></a-input
            >
          </a-form-model-item>
          <a-form-model-item label="展示频次控制" style="margin-bottom: 0px">
            <a-radio-group v-model="basicComForm.impressFrequencyType">
              <a-radio-button :value="0">不限制展示次数</a-radio-button>
              <a-radio-button :value="1">基于设备频次的展示控制</a-radio-button>
            </a-radio-group>
            <div style="display: flex">
              <a-form-model-item
                prop="impressFreqDay"
                ref="impressFreqDay"
                class="text-left"
                :rules="{
                  required: basicComForm.impressFrequencyType == 1,
                  message: '此项为必填项',
                  trigger: 'blur'
                }"
              >
                <a-input
                  v-if="basicComForm.impressFrequencyType == 1"
                  v-model.trim="basicComForm.impressFreqDay"
                  addon-after="次/天"
                  placeholder="输入展示次数"
                  @blur="
                    () => {
                      $refs.impressFreqDay.onFieldBlur()
                    }
                  "
                />
              </a-form-model-item>
              <span
                v-if="basicComForm.impressFrequencyType == 1"
                style="width: 40px; display: inline-block; text-align: center"
              >间隔</span
              >
              <a-form-model-item
                style="margin-left: 10px"
                v-if="basicComForm.impressFrequencyType == 1"
                prop="impressInterval"
                ref="impressInterval"
                :rules="{
                  required: true,
                  message: '此项为必填项',
                  trigger: 'blur'
                }"
              >
                <a-input
                  addon-after="分/每次"
                  v-model.trim="basicComForm.impressInterval"
                  placeholder="输入间隔时间"
                  @blur="
                    () => {
                      $refs.impressInterval.onFieldBlur()
                    }
                  "
                />
              </a-form-model-item>
            </div>
          </a-form-model-item>
          <a-form-model-item
            ref="clickFrequencyType"
            prop="clickFreqDay"
            :autoLink="false"
            label="点击频次控制"
            class="text-left"
            :rules="{
              required: basicComForm.clickFrequencyType == 1,
              message: '此项为必填项',
              trigger: 'blur'
            }"
          >
            <a-radio-group v-model="basicComForm.clickFrequencyType">
              <a-radio-button :value="0">不限制点击次数</a-radio-button>
              <a-radio-button :value="1">基于设备频次的点击控制</a-radio-button>
            </a-radio-group>
            <a-input
              style="width: 200px"
              v-if="basicComForm.clickFrequencyType == 1"
              @blur="
                () => {
                  $refs.clickFrequencyType.onFieldBlur()
                }
              "
              addon-after="次/天"
              v-model.trim="basicComForm.clickFreqDay"
              placeholder="输入点击次数"
            />
          </a-form-model-item>
          <a-form-model-item label="计划状态" class="text-left">
            <a-radio-group v-model="basicComForm.sts">
              <a-radio value="A">开启</a-radio>
              <a-radio value="S">关闭</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
  </div>
</template>
<script>
import { advertiserList } from '@/api/advertisement'
import mixDate from '@/mixins/initDate'
export default {
  name: 'BasicCom',
  mixins: [mixDate],
  data () {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      },
      basicComForm: {
        putTimeType: 0,
        startOrEndTime: [],
        impressFrequencyType: 0,
        clickFrequencyType: 0,
        actionType: 1,
        sts: 'A'
      },
      advertiserTypeOptions: [
        {
          label: '品牌广告',
          value: '1'
        },
        {
          label: '效果广告',
          value: '2'
        }
      ],
      advertiserOptions: []
    }
  },
  created () {
    this.advertiserListFn()
  },
  methods: {
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间
      return current && current < this.initDate(1, 0).start
    },
    // 广告主下拉
    async advertiserListFn (data) {
      const res = await advertiserList(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data
        const newOptions = tempData.map((item) => {
          item.label = item.username
          item.value = item.id
          return item
        })
        this.advertiserOptions = newOptions
      }
      // const tempData = res.data
    },
    handleChangePutTime (date, dateString) {
      this.basicComForm = {
        ...this.basicComForm,
        startOrEndTime: dateString
      }
    }
  }
}
</script>
<style lang="less" scoped>
.flexStyle {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.text-left {
  text-align: left;
}
::v-deep.ant-radio-button-wrapper-checked {
  color: #456bff;
  background: fade(@primary-color, 15%);
  border: 1px solid #456bff;
}
::v-deep.ant-form-item {
  margin-bottom: 20px;
}
</style>
