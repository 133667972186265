<template>
  <div class="advertising-container">
    <div class="advertising-table-content">
      <div class="searchBox">
        <a-input-search style="width: 300px" placeholder="请输入应用/广告位搜索" @search="onSearch"/>
        <div>
          <a-button type="link" size="small" @click="handleCheckedAllSpan('All')">全选</a-button>
          <a-button type="link" size="small" @click="handleCheckedAllSpan('Invert')">反选</a-button>
        </div>
      </div>
      <a-collapse
        class="collapseBox"
        :bordered="false"
        :expand-icon-position="'right'"
        v-for="item in data"
        :key="item.appId"
      >
        <a-collapse-panel key="1" class="text-left collapseBoxHeader" :bordered="false">
          <a-checkbox
            @click="(e) => handleClick(e)"
            @change="(e) => handleChangeCheckedAll(e, item)"
            :indeterminate="item.checkAllList.length !== 0 && item.checkAllList.length !== item.placeList.length"
            :checked="item.checkAllList.length !== 0 && item.checkAllList.length == item.placeList.length"
            slot="header"
          >
            <span>{{ item.appName || '--' }}</span>
          </a-checkbox>
          <a-table
            :row-selection="{ selectedRowKeys: placeIdList, onChange: onSelectChange, onSelect: onSelect }"
            :columns="columns"
            row-key="placeId"
            class="m_table_cust"
            style="padding: 10px 0px"
            :showHeader="false"
            :data-source="item.placeList"
            :pagination="false"
            bordered
          >
          </a-table>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>
<script>
import { getAppPlaceInfo } from '@/api/advertisement'
const columns = [
  {
    title: 'placeName',
    dataIndex: 'placeName'
  }
]
export default {
  name: 'Advertising',
  props: {
    planId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      data: [],
      allData: [],
      searchWord: '',
      columns,
      // 选中的数据
      placeIdList: [],
      oldPlaceIdList: [],
      loading: false,
      isClickAll: false,
      selectedRows: []
    }
  },
  created () {
    const { id } = this.$route.query
    !id && this.getAppPlaceInfoFn()
  },
  methods: {
    // 获取广告位列表
    async getAppPlaceInfoFn (data) {
      const res = await getAppPlaceInfo(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data
        const newData = tempData.map((item) => {
          item.checkAllList = []
          item.placeList = item.placeList.map((element) => {
            element.appId = item.appId
            return element
          })
          return item
        })
        this.data = newData
        this.allData = JSON.parse(JSON.stringify(newData))
      }
    },
    onSelect (record) {
      if (this.placeIdList.length === 0) {
        this.data = this.data.map((ditem) => {
          if (ditem.appId === record.appId) {
            ditem.checkAllList = []
          }
          return ditem
        })
      } else if (this.selectedRows.length === 0) {
        this.data = this.data.map((ditem) => {
          if (ditem.appId === record.appId) {
            ditem.checkAllList = []
          }
          return ditem
        })
      }
    },
    // 点击选择columns
    onSelectChange (selectedRowKeys, selectedRows, index) {
      this.placeIdList = selectedRowKeys
      this.selectedRows = selectedRows
      if (selectedRowKeys.length === 0 || selectedRows.length === 0) {
        return false
      }
      this.data = this.data.map((ditem) => {
        if (selectedRows.length === 0) {
          ditem.checkAllList = []
          return
        }
        if (ditem.appId === selectedRows[0].appId) {
          ditem.checkAllList = selectedRows
        }
        ditem.placeList = ditem.placeList.map((placeItem) => {
          selectedRows.forEach((selectedRowsitem) => {
            if (placeItem.placeId === selectedRowsitem.placeId) {
              placeItem.checked = true
            }
          })
          return placeItem
        })
        return ditem
      })
    },
    // 全选实现
    handleChangeCheckedAll (e, item) {
      const targetValue = e.target.checked
      item.checked = targetValue
      this.data = this.data.map((ditem) => {
        if (ditem.appId === item.appId) {
          item.checked = targetValue
          if (targetValue) {
            ditem.checkAllList = ditem.placeList
            this.placeIdList = [...this.placeIdList, ...ditem.placeList.map((plitem) => plitem.placeId)]
          } else {
            ditem.checkAllList = []
            ditem.placeList = ditem.placeList.map((pitem) => {
              pitem.checked = targetValue
              this.placeIdList = this.placeIdList.filter((placeItem) => placeItem !== pitem.placeId)
              return pitem
            })
          }
        }
        return ditem
      })
    },
    // 搜索 的 全选实现
    handleCheckedAllSpan (type) {
      this.placeIdList = []
      if (type === 'All') {
        // 全选
        if (this.data.every((ditem) => ditem.checkAllList.length === ditem.placeList.length)) {
          this.data = this.data.map((ditem) => {
            ditem.placeList.map((plitem) => {
              plitem.checked = false
            })
            ditem.checkAllList = []
            return ditem
          })
        } else {
          this.data = this.data.map((ditem) => {
            ditem.checkAllList = ditem.placeList
            ditem.placeList.map((plitem) => {
              plitem.checked = true
              this.placeIdList.push(plitem.placeId)
            })
            return ditem
          })
        }
      } else {
        // 反选
        this.data = this.data.map((ditem) => {
          ditem.checkAllList = []
          ditem.placeList.map((plitem) => {
            if (plitem.checked) {
              plitem.checked = false
              ditem.checkAllList = ditem.checkAllList.filter((placeItem) => plitem.placeId !== placeItem)
              this.placeIdList = this.placeIdList.filter((placeItem) => plitem.placeId !== placeItem)
            } else {
              plitem.checked = true
              ditem.checkAllList.push(plitem)
              this.placeIdList.push(plitem.placeId)
            }
            return plitem
          })
          return ditem
        })
      }
    },
    handleClick (e) {
      e.stopPropagation()
    },
    onSearch (value) {
      const tempValue = value
      const str = ['', ...tempValue, ''].join('.*')
      const reg = new RegExp(str)
      if (!tempValue) {
        this.data = this.allData
        return
      }
      const newData = []
      this.data.map((ditem) => {
        ditem.placeList = ditem.placeList.filter((plitem) => reg.test(plitem.placeName || ''))
        ditem.placeList.length !== 0 && newData.push(ditem)
        return ditem
      })
      this.data = newData
    }
  }
}
</script>
<style lang="less" scoped>
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.advertising-table-content {
  position: relative;
}
.collapseBoxHeader {
  border: 0;
  margin-bottom: 15px;
}
.searchBox {
  margin: 15px 0px;
  display: flex;
  justify-content: center;
  padding: 0 30px;
}
::v-deep.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background: #fff !important;
}
::v-deep.ant-collapse-content > .ant-collapse-content-box {
  background: #fff !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
::v-deep.ant-collapse-content {
  background: #fff !important;
}
::v-deep.ant-table-row-selected > .ant-table-selection-column {
  padding-right: 0px !important;
}
::v-deep.m_table_cust table tbody tr td {
  border: none !important;
}
::v-deep.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 1px !important;
}
::v-deep.m_table_cust .ant-table-tbody tr.ant-table-row-selected td {
  background: none !important;
  &:hover {
    background: #eaefff;
  }
}
::v-deep.m_table_cust .ant-table-tbody tr.ant-table-row-selected td {
  background: none !important;
}
::v-deep.m_table_cust .ant-table-tbody tr.ant-table-row-selected {
  &:hover {
    background: #eaefff;
  }
}
</style>
